import { Box, Card, CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'
import { SOUNDS, useAudio } from '../hooks/useAudio';
import CategoryCard from './CategoryCard';
import { S_TYPE } from './Grid';
import { FormattedMessage} from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(41, 95, 155, 0.65);',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3000,
  },
  top: {
    background: '#DFDFDF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px 4px 0 0',
    '& img': {
      margin: '0 22px',
      transform: 'scale(1.3)'
    },
    '& p': {
      width: 'fit-content'
    }
  },
  card: {
    width: '48%',
    overflow: 'visible',
    background: '#FFFFFF',
    boxShadow: '-4px 4px 5px 3px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    position: 'relative',
  },
  title: {
    flex: 1,
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#FFFFFF',
  },
  content: {
    padding: '90px',
    position: 'relative',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: '100%',
    maxWidth: '441px',
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.15px',
    textTransform: 'uppercase',
    border: '1px solid #575757',
    boxSizing: 'border-box',
    borderRadius: '24px',
    marginBottom: '1rem',
    color: '#575757',
  },
  timer: {
    color: '#78AEEA',
    margin: '20px',
    position: 'absolute',
    right: 0,
    bottom: 0
  },
  question: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '28px',
    letterSpacing: '0.15px',
    color: '#575757',
    marginBottom: '25px',
  },
  categories: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '300px'
  },
  categoryContainer: {
    flex: '40%',
    margin: '5px',
    cursor: isMyTurn => isMyTurn ? 'pointer' : 'default',
    border: '2px solid #FFFFFF',
  },
  selected: {
    border: '2px solid #575757',
    borderRadius: '4px'
  },
  notActive: {
    pointerEvents: 'none',
    cursor: 'default'
  }
}))

interface MulticolorModalProps {
  color: string; // color of the player playing
  isMyTurn: boolean; // if it's my turn
  nickname: string;
  timeout: () => void; // pass up if timer ran out
  chooseCategories: (category1: any, category2: any) => void; // pass up the 2 categories chosen
  topic: any;
  finalCategory: any;
  selectedCategory: (category: any) => void;
  startTimestamp: number;
  endTimestamp: number;
}

/**
 * Clicking 2 categories pass up the 2 categories chosen to send it to the server 
 */
const MulticolorModal: FC<MulticolorModalProps> = ({ color, isMyTurn, nickname, timeout, chooseCategories, topic, finalCategory, selectedCategory, startTimestamp, endTimestamp }) => {
  const classes = useStyles(isMyTurn);
  const [progress, setProgress] = useState<number>(100);
  const [playerChose, setPlayerChose] = useState<boolean>(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [selectedGreen, setSelectedGreen] = useState<boolean>(false)
  const [selectedBlue, setSelectedBlue] = useState<boolean>(false)
  const [selectedYellow, setSelectedYellow] = useState<boolean>(false)
  const [selectedOrange, setSelectedOrange] = useState<boolean>(false)

  const buttonClickAudio = useAudio(SOUNDS.BUTTON_CLICK, {})

  useEffect(() => {
    setCategories([]);
    setSelectedGreen(false);
    setSelectedBlue(false);
    setSelectedYellow(false);
    setSelectedOrange(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    finalCategory = -1;
  }, [])

  useEffect(() => {
    let timer: any = null
    if (progress > 0 && !timer && !playerChose) {
      timer = setInterval(() => {
        let timeLeft = endTimestamp - Date.now();
        let percent = timeLeft * 100 / (endTimestamp - startTimestamp);
        setProgress(prevProgress => prevProgress > 0 ? percent : 0)
      }, 1000)
    }
    else {
      clearInterval(timer)
      if (categories.length !== 2 || !playerChose) timeout();
    }
    return () => {
      clearInterval(timer);
    }
  }, [progress, playerChose, startTimestamp, endTimestamp, timeout])

  useEffect(() => {
    if (categories.length === 2) {
      chooseCategories(categories[0].type, categories[1].type);
    }
  }, [categories, chooseCategories])

  useEffect(() => {
    if (finalCategory !== -1) {
      if (categories.length < 2) {
        switch (finalCategory) {
          case S_TYPE.GREEN:
            if (!selectedGreen) {
              let cs = [...categories];
              cs.push({ type: finalCategory });
              setCategories(cs);
              setSelectedGreen(true);
              buttonClickAudio.play()
            }
            break;
          case S_TYPE.BLUE:
            if (!selectedBlue) {
              let cs = [...categories];
              cs.push({ type: finalCategory });
              setCategories(cs);
              setSelectedBlue(true);
              buttonClickAudio.play()
            }
            break;
          case S_TYPE.YELLOW:
            if (!selectedYellow) {
              let cs = [...categories];
              cs.push({ type: finalCategory });
              setCategories(cs);
              setSelectedYellow(true);
              buttonClickAudio.play()
            }
            break;
          case S_TYPE.ORANGE:
            if (!selectedOrange) {
              let cs = [...categories];
              cs.push({ type: finalCategory });
              setCategories(cs);
              setSelectedOrange(true);
              buttonClickAudio.play()
            }
            break;
        }
      }
      else {
        setPlayerChose(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalCategory])

  const getCategory = (type: S_TYPE) => {
    switch (type) {
      case 1: return topic.subtopic1;
      case 2: return topic.subtopic2;
      case 3: return topic.subtopic3;
      case 4: return topic.subtopic4;
    }
    return topic.subtopic1;
  }

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <Box className={classes.top}>
          <Typography className={classes.title} style={{ textAlign: 'right' }}>
            <FormattedMessage id="gameView.Game.components.MulticolorModal.play" />
          </Typography>
          <img src={`/static/images/player_${color}.png`} alt="" />
          <Typography className={classes.title} style={{ textAlign: 'left' }}>{nickname}</Typography>
        </Box>
        <Box className={classes.content}>
          <Typography className={classes.question}>
            <FormattedMessage id="gameView.Game.components.MulticolorModal.selectCategory" />
          </Typography>
          <Box className={classes.categories}>
            <Box className={`${classes.categoryContainer} 
            ${selectedGreen ? classes.selected : ''} 
            ${categories.length === 2 ? classes.notActive : ''}`}
              onClick={() => {
                selectedCategory(S_TYPE.GREEN);
              }}
            >
              <CategoryCard title={getCategory(S_TYPE.GREEN)} type={S_TYPE.GREEN} inline={true} />
            </Box>
            <Box className={`${classes.categoryContainer} ${selectedBlue ? classes.selected : ''} ${categories.length === 2 ? classes.notActive : ''}`} onClick={() => { selectedCategory(S_TYPE.BLUE); }}>
              <CategoryCard title={getCategory(S_TYPE.BLUE)} type={S_TYPE.BLUE} inline={true} />
            </Box>
            <Box className={`${classes.categoryContainer} ${selectedYellow ? classes.selected : ''} ${categories.length === 2 ? classes.notActive : ''}`} onClick={() => { selectedCategory(S_TYPE.YELLOW); }}>
              <CategoryCard title={getCategory(S_TYPE.YELLOW)} type={S_TYPE.YELLOW} inline={true} />
            </Box>
            <Box className={`${classes.categoryContainer} ${selectedOrange ? classes.selected : ''} ${categories.length === 2 ? classes.notActive : ''}`} onClick={() => { selectedCategory(S_TYPE.ORANGE); }}>
              <CategoryCard title={getCategory(S_TYPE.ORANGE)} type={S_TYPE.ORANGE} inline={true} />
            </Box>
          </Box>
          <CircularProgress className={classes.timer} variant="determinate" value={progress} />
        </Box>
      </Card>
    </Box>
  )
}

export default MulticolorModal
