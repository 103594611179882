import { Box, makeStyles, Theme, Snackbar } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';
import AudioSettings from '../../shared/AudioSettings';
import LanguageSettings from '../../shared/LangSettings';
import Background from '../../shared/Background';
import { audioService } from '../gameView/Game/hooks/audioService.service';
import HostRoomCard from './HostRoomCard';
import JoinRoomCard from './JoinRoomCard';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../shared/config';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
  },
  content: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
  },
  logo: {
    marginBottom: '4rem',
    marginTop: '4rem',
    display: 'flex',
    alignItems: 'center'
  },
  audioSettings: {
    position: 'fixed',
    zIndex: 1024,
    top: 0,
    right: '32px',
    display: 'flex',
    flexDirection: 'row',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(3),
    },
  },
}));

interface HomeProps {
  socket: Socket;
  switchLanguage: (language: string) => void;
  locale: string;
}

const HomeView: FC<HomeProps> = ({ socket, switchLanguage, locale }) => {
  const classes = useStyles();
  const { organization_id } = useParams<{ organization_id: string }>();
  const [logoUrl, setLogoUrl] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const intl = useIntl();

  const handleSnackbarClose = () => setOpenSnackbar(false);

  const getOrganization = async () => {
    const isNumber = !isNaN(Number(organization_id));
    const params = isNumber
      ? { organization_id: parseInt(organization_id) }
      : { name: organization_id.toLowerCase() };


    axios.post(`${config.apiUrl}/organization/get`, params)
      .then(response => {
        if (response.data && response.data.logo) {
          setLogoUrl(response.data.logo);
          history.push(`/${response.data.organization_id}`);
          setLoading(false)
        }
      })
      .catch(() => {
        console.error(intl.formatMessage({ id: "homeView.index.logoError" }));
        setOpenSnackbar(true);
        history.push('/0');
      });

  };

  useEffect(() => {
    audioService.init();
    getOrganization();
  }, [organization_id]);

  if (isLoading) {
    return (null)
  }
  else {
    return (
      <div className={classes.root}>
        <Background color='orange' />
        <Box className={classes.content}>
          <Box className={classes.audioSettings}>
            <AudioSettings />
            <LanguageSettings switchLanguage={switchLanguage} currentLanguage={locale} />
          </Box>
          <Box className={classes.logo}>
            {logoUrl &&
              <Box>
                <img
                  src={logoUrl}
                  alt={intl.formatMessage({ id: "homeView.index.orgLogoAlt" })}
                  style={{ width: '300px' }}
                />
              </Box>
            }
            <Box style={{ marginLeft: "2rem" }}>
              <img src="/static/images/logo.png" alt={intl.formatMessage({ id: "homeView.index.appLogoAlt" })} />
            </Box>
          </Box>
          <JoinRoomCard socket={socket} />
          <HostRoomCard socket={socket} />
        </Box>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={<FormattedMessage id="homeView.index.notFound" />}
        />
      </div>
    );
  }
};

export default HomeView;
