import { Box, Card, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'

import CloseIcon from '@material-ui/icons/Close';
import { SOUNDS, useAudio } from './Game/hooks/useAudio';
import { PlayerMeta } from './Game/models/Player.model';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    width: '333px',
    maxHeight: '503px',
    right: '0px',
    top: '140px',
    padding: '18px'
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  playerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  title: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#575757'
  },
  nickname: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#C2C2C2'
  },
  row: {
    display: 'flex',
    '& img': {
      height: '2rem',
      marginRight: '12px',
    }
  }, 
  close: {
    cursor: 'pointer',
  },
  close2: {
    cursor: 'pointer',
    width: '20px'
  },
  rules: {
    maxHeight: '410px',
    overflow: 'auto',
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#575757',
    textAlign: 'left',
    marginBottom: '0.5rem'
  },
}))

interface PlayerListProps {
  onClose: () => void;
  players: PlayerMeta[];
  maxPlayers: number;
  onKick: (index: number) => void;
}

const PlayerList: FC<PlayerListProps> = ({onClose, players, maxPlayers, onKick}) => {
  const classes = useStyles();

  const buttonClickAudio = useAudio(SOUNDS.BUTTON_CLICK, {})
  
  const pending = [];
  for (let i = 0; i < maxPlayers - players.length; ++i) {
    pending.push(i+players.length);
  }

  return (
    <Card className={classes.root}>
      <Box className={classes.top}>
        <Box className={classes.row}>
          <Typography className={classes.title}>
            <FormattedMessage id="gameView.PlayerList.players" />
          </Typography>
        </Box>
        <CloseIcon className={classes.close} color="inherit" onClick={()=> {onClose(); buttonClickAudio.play()}}/>
      </Box>
      <Box className={classes.rules}>
        <Typography className={classes.text}>
          <FormattedMessage id="gameView.PlayerList.connected" />
        </Typography>
        {
          players.map((player, index) =>  {
            let url = '/static/images/player_';
            switch (index) {
              case 0: url += 'red.png'; break;
              case 1: url += 'green.png'; break;
              case 2: url += 'blue.png'; break;
              case 3: url += 'orange.png'; break;
              case 4: url += 'yellow.png'; break;
              case 5: url += 'pink.png'; break;
            }
            return (<Box key={'pl-'+player.user.nickname} className={`${classes.playerRow}`}>
              <Box className={`${classes.row}`}>
                <img src={url} alt=""/>
                <Typography className={classes.nickname}>{player.user.nickname}</Typography>
              </Box>
              {
                index !== 0 && 
                <CloseIcon className={classes.close2} color="inherit" onClick={() => {onKick(player.user.id); onClose(); buttonClickAudio.play()}}/>
              }
            </Box>)
          }
          )
        }
        <Typography className={classes.text}>
          <FormattedMessage id="gameView.PlayerList.pending" />
        </Typography>
        {
          pending.map((p) =>  {
            let url = '/static/images/player_';
            switch (p) {
              case 0: url += 'red.png'; break;
              case 1: url += 'green.png'; break;
              case 2: url += 'blue.png'; break;
              case 3: url += 'orange.png'; break;
              case 4: url += 'yellow.png'; break;
              case 5: url += 'pink.png'; break;
            }
            return (<Box key={'pl-'+p} className={`${classes.playerRow}`}>
              <Box className={`${classes.row}`}>
                <img src={url} alt=""/>
                <Typography className={classes.nickname}>
                  <FormattedMessage id="gameView.PlayerList.player" /> {p}
                </Typography>
              </Box>
            </Box>)
          }
          )
        }
      </Box>
    </Card>
  )
}

export default PlayerList
