import { User } from "../../../../shared/models";

export type PlayerMeta = {
  id: number;
  joinedAt: number;
  status: string;
  noActivityTurns: number;
  playerTurn: number; // the order turn of the player
  playerPosition: number;
  passTurn: number;
  isAdmin: boolean;
  color: string;
  user: User;
  positionInCell: number;
}

const playerMargin = 5;
const playerWidth = 98;
const playerHeight = 59;

export const playerTotalWidth = playerWidth + playerMargin;
export const playerTotalHeight = playerHeight + playerMargin;