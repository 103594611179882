import './App.css';
import { 
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import HomeView from './views/homeView';
import GameView from './views/gameView';

import { io } from 'socket.io-client';
import { config } from './shared/config';

import { SOUNDS, useAudio } from './views/gameView/Game/hooks/useAudio';

import { useEffect, useState } from 'react';

import { IntlProvider } from 'react-intl';

function App() {
  const socket = io.connect(config.apiUrl, { 
    path: '/api/sockets',
    transports: ['websocket'],
  });

  const [messages, setMessages] = useState({});
  const [locale, setLocale] = useState(localStorage.getItem('locale') || 'es'); 
  const [loading, setLoading] = useState(true);
  var flatten = require('flat');

  async function loadLocaleData(locale) {
    switch (locale) {
      case 'es':
        return import('./language/es.json');
      case 'cat':
        return import('./language/cat.json');
      default:
        return import('./language/es.json');
    }
  }

  useEffect(() => {
    async function fetchMessages() {
      setLoading(true); 
      const loadedMessages = await loadLocaleData(locale);
      setMessages(loadedMessages.default); 
      setLoading(false); 
    }

    fetchMessages();
  }, [locale]);

  const switchLanguage = (lang) => {
    setLocale(lang);
    localStorage.setItem('locale', lang);
  };

  if (loading) {
    return <div></div>;
  }

  return (
    <div className="App">
      <IntlProvider locale={locale} messages={flatten(messages)} key={locale}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/0" />
            </Route>
            <Route exact path="/:organization_id">
              <HomeView socket={socket} switchLanguage={switchLanguage} locale={locale} />
            </Route>
            <Route path="/game/:code">
              <GameView socket={socket} />
            </Route>
          </Switch>
        </Router>
      </IntlProvider>
    </div>
  );
}

export default App;