import { Box, Card, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { FC, useState } from 'react'

import InfoIcon from '@material-ui/icons/Info';
import { green } from '@material-ui/core/colors';
import Rules from './Rules';
import { SOUNDS, useAudio } from './Game/hooks/useAudio';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center'
  },
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(41, 95, 155, 0.65)',
  },
  card: {
    position: 'absolute',
    top: '7rem',
    maxWidth: '588px',
    maxHeight: '196px',
    background: '#FFFFFF',
    boxShadow: '0px 11px 15px rgba(0, 0, 0, 0.2), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    padding: '16px 25px',
    textAlign: 'left'
  },
  title: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '19px',
    lineHeight: '26px',
    letterSpacing: '0.25px',    
    color: 'rgba(87, 87, 87, 0.87)',
    marginBottom: '22px',
  },
  description: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',    
    color: 'rgba(180, 179, 179, 0.54)',
    marginBottom: '32px',
  },
  seeRulesContainer: {
    display: 'flex',
    cursor: 'pointer'
  },
  seeRules: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    color: '#83CD24',
  }, 
  showingRules: {
    position: 'absolute',
    top: '7rem',
    maxWidth: '588px',
    maxHeight: '64%',
    background: '#FFFFFF',
    boxShadow: '0px 11px 15px rgba(0, 0, 0, 0.2), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    padding: '16px 25px',
  }
}))

const WaitingModal: FC = () => {
  const classes = useStyles();
  const [showRules, setShowRules] = useState<boolean>(false);

  const buttonClickAudio = useAudio(SOUNDS.BUTTON_CLICK, {})

  return (
    <Box className={classes.root}>
      <Box className={classes.background}></Box>
      {
        !showRules &&
        <Card className={classes.card}>
          <Typography className={classes.title}>
            <FormattedMessage id="gameView.WaitingModal.wait" />
          </Typography>
          <Typography className={classes.description}>
            <FormattedMessage id="gameView.WaitingModal.waitingPlayers" />
          </Typography>
          <Box className={classes.seeRulesContainer} >
            <InfoIcon style={{ color: green[500], marginRight: '10px', marginLeft: '8px' }}/>
            <Typography className={classes.seeRules} onClick={() => {setShowRules(true); buttonClickAudio.play()}}>
              <FormattedMessage id="gameView.WaitingModal.seeRules" /> 
            </Typography>
          </Box>
        </Card>
      }
      {
        showRules &&
        <Box className={classes.card + ' ' + classes.showingRules}>
          <Rules onClose={() => {setShowRules(false); buttonClickAudio.play()}} inline={true}/>
        </Box>
      }
    </Box>
  )
}

export default WaitingModal
