import { Box, Card, makeStyles, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import LanguageIcon from '@material-ui/icons/Language';

import { SOUNDS, useAudio } from '../views/gameView/Game/hooks/useAudio';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '333px',
    maxHeight: '134px',
    right: '0px',
    padding: '18px',
  },
  container: {
    textAlign: 'end',
  },
  icon: {
    cursor: 'pointer',
    marginTop: '18px',
    color: 'white',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
  },
  row: {
    display: 'flex',
  },
  close: {
    cursor: 'pointer',
  },
  setting: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '12px',
  },
  option: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '26px',
    cursor: 'pointer',
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

interface LanguageSettingsProps {
    switchLanguage: (language: string) => void;
    currentLanguage: string;
}

const LanguageSettings: FC<LanguageSettingsProps> = ({ switchLanguage, currentLanguage }) => {
  const classes = useStyles();
  const [showLanguageSettings, setShowLanguageSettings] = useState<boolean>(false);
  const buttonClickAudio = useAudio(SOUNDS.BUTTON_CLICK, {});

  const handleLanguageChange = (language: string) => {
    switchLanguage(language);
    setShowLanguageSettings(false);
    buttonClickAudio.play();
  };

  return (
    <Box className={classes.container}>
      <LanguageIcon 
        className={classes.icon}
        onClick={() => { setShowLanguageSettings(true); buttonClickAudio.play(); }}
      />
      {showLanguageSettings && (
        <Card className={classes.root}>
          <Box className={classes.top}>
            <Box className={classes.row}>
              <LanguageIcon color="action" style={{ marginRight: '10px' }} />
              <Typography className={classes.title}>IDIOMA</Typography>
            </Box>
            <CloseIcon
              className={classes.close}
              color="inherit"
              onClick={() => { setShowLanguageSettings(false); buttonClickAudio.play(); }}
            />
          </Box>
          <Box className={classes.setting}>
          <Typography
              className={`${classes.option} ${currentLanguage === 'es' ? classes.selected : ''}`}
              onClick={() => handleLanguageChange('es')}
            >
              Español
            </Typography>
            <Typography
              className={`${classes.option} ${currentLanguage === 'cat' ? classes.selected : ''}`}
              onClick={() => handleLanguageChange('cat')}
            >
              Català
            </Typography>
          </Box>
        </Card>
      )}
    </Box>
  );
};

export default LanguageSettings;
