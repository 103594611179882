import { Box, Card, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';

import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { SOUNDS, useAudio } from './Game/hooks/useAudio';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    width: '333px',
    maxHeight: '503px',
    right: '0px',
    top: '95px',
    padding: '18px',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
  },
  row: {
    display: 'flex',
  },
  close: {
    cursor: 'pointer',
  },
  rules: {
    maxHeight: '410px',
    overflow: 'auto',
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textAlign: 'left',
    color: '#000000',
  },
  bold: {
    fontWeight: 700, // Explicitly set to bold
  },
  inline: {
    position: 'initial',
    width: '100%',
    maxHeight: '100%',
    padding: 0,
    boxShadow: 'none',
  },
}));

interface RulesProps {
  onClose: () => void;
  inline: boolean;
}

const Rules: FC<RulesProps> = ({ onClose, inline }) => {
  const classes = useStyles();
  const intl = useIntl();
  const buttonClickAudio = useAudio(SOUNDS.BUTTON_CLICK, {});

  // Get the content and parse it into React elements
  const rawContent = intl.formatMessage({ id: 'gameView.Rules.content' });

  const parseContent = (content: string) => {
    const parts = content.split(/(<b>.*?<\/b>|<br \/>|&nbsp;)/g);
  
    return parts.map((part, index) => {
      if (part.startsWith('<b>') && part.endsWith('</b>')) {
        // Extract text inside the <b> tags
        const boldText = part.slice(3, -4);
        return (
          <strong key={index} className={classes.bold}>
            {boldText}
          </strong>
        );
      }
      if (part === '<br />') {
        return <br key={index} />;
      }
      if (part === '&nbsp;') {
        return '\u00A0'; // Non-breaking space
      }
      return part; // Plain text
    });
  };

  return (
    <Card className={classes.root + (inline ? ' ' + classes.inline : '')}>
      <Box className={classes.top}>
        <Box className={classes.row}>
          <InfoIcon color="action" style={{ marginRight: '10px' }} />
          <Typography className={classes.title}>
            <FormattedMessage id="gameView.Rules.rules" />
          </Typography>
        </Box>
        <CloseIcon
          className={classes.close}
          color="inherit"
          onClick={() => {
            onClose();
            buttonClickAudio.play();
          }}
        />
      </Box>
      <Box className={classes.rules}>
        <Typography component="div" className={classes.text}>
          {parseContent(rawContent)}
        </Typography>
      </Box>
    </Card>
  );
};

export default Rules;
