import React, { useState } from 'react'
import type { FC } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Card, TextField, Theme, Typography } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { SOUNDS, useAudio } from '../gameView/Game/hooks/useAudio';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '433px',
    minHeight: '223px',
    padding: '22px 24px',
    borderRadius: '4px', 
    background: '#FFFFFF',
    boxShadow: ' 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12)',
    marginBottom: '2.5em',
  },
  title: {
    height: '40px',
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '0.15px',
    textAlign: 'center',
    color: '#575757',
    marginBottom: '2rem',
  },
  textfield: {
    '& label': {
      fontFamily: 'Luckiest Guy',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      letterSpacing: '0.15px',
      color: 'rgba(180, 179, 179, 0.5)',
    },
    '& label.MuiInputLabel-outlined': {
      width: 'calc(100% - 28px)'
    },
    '& label.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      width: 'unset',
    },
    '& label.MuiFormLabel-root.Mui-focused': {
      color: '#FFA258'
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'unset'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.66)'
    },
    width: '100%',
    height: '40px',
    margin: 'none',
    marginBottom: '1rem',
  },
  input: {
    '&.MuiInputBase-root': {
      color: '#575757',
    },
    '&.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused': {
      borderColor: 'none',
    },
    boxSizing: 'border-box',
    borderRadius: '24px',
  },
  button: {
    color: '#FFFFFF',
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.45px',
    textTransform: 'uppercase',
    width: '100%',
    boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: '24px',
  },
  error: {
    color: 'red',
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '26px'
  }
}))

interface JoinRoomCardProps {
  socket: any;
}

const JoinRoomCard: FC<JoinRoomCardProps> = ({socket}) =>  {
  const classes = useStyles();
  const [nickname, setNickname] = useState<string>('');
  const [roomCode, setRoomCode] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [errorCodeMsg, setErrorCodeMsg] = useState<string>('');

  const buttonClickAudio = useAudio(SOUNDS.BUTTON_CLICK, {})

  const history = useHistory();
  const intl = useIntl();

  const onJoin = () => {
    buttonClickAudio.play();
    if (nickname && roomCode) {
      socket.emit('joinRoom', {userId: localStorage.getItem('userId'), nickname, roomCode}, (response: any) => {
        setErrorMsg('');
        setErrorCodeMsg('');
        if (response.status.code === 200) {
          localStorage.setItem('userId', response.data.user.id);
          history.push(`/game/${response.data.room.roomCode}`)
        } else {
          switch(response.status.code) {
            case 400: setErrorMsg(intl.formatMessage({ id: "homeView.JoinRoomCard.case400" })); break;
            case 401: setErrorCodeMsg(intl.formatMessage({ id: "homeView.JoinRoomCard.case401" })); break;
            case 404: setErrorCodeMsg(intl.formatMessage({ id: "homeView.JoinRoomCard.case404" })); break;
            case 403: setErrorCodeMsg(intl.formatMessage({ id: "homeView.JoinRoomCard.case403" })); break;
          }
        }
      }
      );
    } else {
      setErrorMsg('Fill inputs')
    }
  }

  const orangeTheme = createTheme({
    palette: {
      primary: {
        main: '#FFA258',
      }
    }
  });
  const greenTheme = createTheme({
    palette: {
      primary: {
        main: '#83CD24',
      }
    }
  });

  return (
    <Card
      className={classes.root}
    >
      <Box >
        <Typography
          variant='h6'
          className={classes.title}
        >
          <FormattedMessage id="homeView.JoinRoomCard.join" />
        </Typography>
      </Box>
      <form >
        <ThemeProvider theme={orangeTheme}>
          <TextField
            className={classes.textfield}
            inputProps={{style: {textAlign: 'center'}}}
            InputProps={{className: classes.input}}
            label={intl.formatMessage({ id: "homeView.JoinRoomCard.nameInput" })}
            variant="outlined"
            id="nickname"
            size="small"
            onChange={e => setNickname(e.target.value)}
            error={nickname === '' && errorMsg !== ''}
          />
          <TextField
            className={classes.textfield}
            inputProps={{style: {textAlign: 'center'}}}
            InputProps={{className: classes.input}}
            label={errorCodeMsg ? errorCodeMsg: intl.formatMessage({ id: "homeView.JoinRoomCard.codeInput" }) }
            variant="outlined"
            id="roomCode"
            size="small"
            onChange={e => setRoomCode(e.target.value)}
            error={(roomCode === '' && errorMsg !== '') || errorCodeMsg !== ''}
          />
        </ThemeProvider>
        <ThemeProvider theme={greenTheme}>
          {
            errorMsg &&
            <Box className={classes.error}>
              {errorMsg}
            </Box>
          }
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {onJoin();}}
          >
            <FormattedMessage id="homeView.JoinRoomCard.enter" />
          </Button>
        </ThemeProvider>
      </form>
    </Card>
  )
}

export default JoinRoomCard
