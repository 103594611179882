import { Box, Button, Card, CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { Question } from '../models/QuestionModal.model';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '../../../../shared/Translation';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(41, 95, 155, 0.65);',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 3000,
    },
    card: {
        width: '48%',
        overflow: 'visible',
        background: '#FFFFFF',
        boxShadow: '-4px 4px 5px 3px rgba(0, 0, 0, 0.25)',
        borderRadius: '4px',
        position: 'relative',
    },
    content: {
        padding: '90px',
        position: 'relative',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        width: '100%',
        maxWidth: '441px',
        fontFamily: 'Luckiest Guy',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.15px',
        textTransform: 'uppercase',
        border: '1px solid #575757',
        boxSizing: 'border-box',
        borderRadius: '24px',
        marginBottom: '1rem',
        color: '#575757',
    },
    timer: {
        color: '#78AEEA',
        margin: '20px',
        position: 'absolute',
        right: 0,
        bottom: 0,
    },
    comment: {
        fontFamily: 'Luckiest Guy',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '24px',
        lineHeight: '28px',
        letterSpacing: '0.15px',
        color: '#575757',
        marginBottom: '25px',
    },
}));

interface CommentModalProps {
    isCorrect: boolean;
    question: Question;
    isMyTurn: boolean; // if it's my turn
    commentModalContinueClick: any;
}

const CommentModal: FC<CommentModalProps> = ({ isCorrect, question, isMyTurn, commentModalContinueClick }) => {
    const classes = useStyles();
    const [progress, setProgress] = useState<number>(100);
    const [continueClicked, setContinueClicked] = useState<boolean>(false);
    const intl = useIntl();
    const  {locale} = useIntl();
    const [comment, setComment] = useState<string>('');
    const [checked, isChecked] = useState<boolean>(false);

    useEffect(() => {
        const fetchTranslation = async () => {
            const translatedComment = await translate(question.comment || '');
            setComment(translatedComment);
        };
        if (!checked) {
            isChecked(true);
            if ( locale !== 'es') {
                fetchTranslation();
            } else {
                setComment(question.comment || '')
            }
        }
    });

    useEffect(() => {
        let timer: any = null;

        if (progress > 0 && !timer) {
            timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress > 0 ? prevProgress - 1 : 0));
            }, 200);
        } else {
            clearInterval(timer);
            handleContinue();
        }

        return () => {
            clearInterval(timer);
        };
    }, [progress]);

    const handleContinue = () => {
        setContinueClicked(true);
        // Lógica al hacer clic en "Continuar"
        // Cerrar el modal
        commentModalContinueClick(null, isCorrect);
    };

    return (
        <Box className={classes.root} style={{ display: 'flex' }}>
            <Card className={classes.card}>
                <Box className={classes.content}>
                    <Typography className={classes.comment}>{isCorrect ? intl.formatMessage({id:"gameView.Game.components.CommentModal.correct"}) : intl.formatMessage({id:"gameView.Game.components.CommentModal.incorrect"})}</Typography>
                    <Typography className={classes.comment}>{comment}</Typography>
                    <CircularProgress className={classes.timer} variant="determinate" value={progress} />
                    <Box className={classes.buttons}>
                        {isMyTurn &&
                            <Button
                                className={classes.button}
                                onClick={handleContinue}
                                disabled={progress <= 0 || continueClicked}
                            >
                                <FormattedMessage id="gameView.Game.components.CommentModal.continue" />
                            </Button>
                        }
                    </Box>
                </Box>
            </Card>
        </Box>
    );
};

export default CommentModal;
