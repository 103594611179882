import { Box, Button, Card, CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { FC, useEffect, useRef, useState } from 'react'
import { SOUNDS, useAudio } from '../hooks/useAudio';
import Dice from './dice';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(41, 95, 155, 0.65);',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3000,
  },
  top: {
    background: '#DFDFDF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px 4px 0 0',
    '& img': {
      margin: '0 22px',
      transform: 'scale(1.3)'
    },
    '& p': {
      width: 'fit-content'
    }
  },
  card: {
    width: '48%',
    overflow: 'visible',
    background: '#FFFFFF',
    boxShadow: '-4px 4px 5px 3px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    position: 'relative',
  },
  title: {
    flex: 1,
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#575757',
  },
  content: {
    padding: '42px',
    position: 'relative',
  },
  button: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.45px',
    textTransform: 'uppercase',
    width: 'fit-content',
    boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: '24px',
    padding: '8px 31px',
    color: 'white',
    background: '#FF933E',
    '&:hover': {
      background: '#E58437',
    }
  },
  diceContainer: {
    marginBottom: '3.6rem'
  },
  timer: {
    color: '#78AEEA',
    margin: '20px',
    position: 'absolute',
    right: 0,
    bottom: 0
  },
}))

interface DiceModalProps {
  color: string; // color of the player playing
  isMyTurn: boolean; // if it's my turn
  nickname: string;
  rolled: (value: number) => void; // pass up the random value
  timeout: () => void; // pass up if timer ran out
  finalRoll: number[]; // passing down from socket the final random value
  startTimestamp: number;
  endTimestamp: number;
}

/**
 * Clicking the button generates a random number from 1 to 6, and pass it up to send it to the server 
 * which returns a socket event that updates finalRoll and at updating finalRoll the dice will roll with this number 
 * for all sockets
 */
const DiceModal: FC<DiceModalProps> = ({ color, isMyTurn, nickname, rolled, timeout, finalRoll, startTimestamp, endTimestamp }) => {
  const classes = useStyles();
  const [progress, setProgress] = useState<number>(100);
  const [playerRolled, setPlayerRolled] = useState<boolean>(false);

  const buttonClickAudio = useAudio(SOUNDS.BUTTON_CLICK, {})

  useEffect(() => {
    let timer: any = null
    if (progress > 0 && !timer && !playerRolled) {
      timer = setInterval(() => {
        let timeLeft = endTimestamp - Date.now();
        let percent = timeLeft * 100 / (endTimestamp - startTimestamp);
        setProgress(prevProgress => prevProgress > 0 ? percent : 0)
      }, 1000)
    } else {
      clearInterval(timer)
      if (!playerRolled) timeout()
    }
    return () => {
      clearInterval(timer);
    }
  }, [progress, playerRolled, startTimestamp, endTimestamp, timeout])

  const diceRef1 = useRef<HTMLDivElement>(null);
  const diceRef2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (diceRef1 && diceRef1.current && diceRef1.current.children[0] && diceRef2 && diceRef2.current && diceRef2.current.children[0]) {

      diceRef1.current.style.pointerEvents = 'auto';
      let el = diceRef1.current.children[0] as any;
      el.click();
      diceRef1.current.style.pointerEvents = 'none';

      diceRef2.current.style.pointerEvents = 'auto';
      let el2 = diceRef2.current.children[0] as any;
      el2.click();
      diceRef2.current.style.pointerEvents = 'none';
      setPlayerRolled(true);
    }

  }, [finalRoll])

  const generateNumber = () => {
    const value = Math.floor((Math.random() * (12 - 2 + 1) + 2));
    rolled(value);
  }

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <Box className={classes.top}>
          <Typography className={classes.title} style={{ textAlign: 'right' }}>
            <FormattedMessage id="gameView.Game.components.DiceModal.play" />
          </Typography>
          <img src={`/static/images/player_${color}.png`} alt="" />
          <Typography className={classes.title} style={{ textAlign: 'left' }}>{nickname}</Typography>
        </Box>
        <Box className={classes.content}>
          <div ref={diceRef1} className={classes.diceContainer} style={{ pointerEvents: "none" }}>
            <Dice size={87} cheatValue={finalRoll[0]} />
          </div>
          <div ref={diceRef2} className={classes.diceContainer} style={{ pointerEvents: "none" }}>
            <Dice size={87} cheatValue={finalRoll[1]} />
          </div>
          {isMyTurn &&
            <Button className={classes.button} variant="contained" onClick={() => { buttonClickAudio.play(); generateNumber() }} disabled={playerRolled}>
              <FormattedMessage id="gameView.Game.components.DiceModal.dice" />
            </Button>
          }
          <CircularProgress className={classes.timer} variant="determinate" value={progress} />
        </Box>
      </Card>
    </Box>
  )
}

export default DiceModal
