import { config } from './config';
import axios from 'axios';

export const translate = async (text: string) => {
    const apiKey = config.apiKey;
    const apiURL = "https://api.openai.com/v1/chat/completions";
    const content = "Translate to catalan the following text mantasining it's structure: " + text;
    const messages = [{ 
        role: "user", 
        content: content
    }];
    try {
        const response = await axios.post(
        apiURL,
        {
            model: "gpt-4o",
            messages: messages,
            max_tokens: 150,
            temperature: 0.7,
            top_p: 1,
            stop: ["\n", "\n\n"],
        },
        {
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
            },
        }
        );
        return response.data.choices[0].message.content;
    } catch (error: any) {
        console.error("Error making OpenAI request:", error.response.data);
    }
}