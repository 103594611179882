import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Card, makeStyles, MenuItem, TextField, Theme, Typography } from '@material-ui/core'
import axios from 'axios';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { config } from '../../shared/config';
import { Topic } from '../../shared/models';
import { SOUNDS, useAudio } from '../gameView/Game/hooks/useAudio';
import { FormattedMessage, useIntl } from 'react-intl';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '433px',
    minHeight: '325px',
    padding: '22px 24px',
    borderRadius: '4px',
    background: '#FFFFFF',
    boxShadow: ' 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12)',
    marginBottom: '1rem',
  },
  title: {
    height: '40px',
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '0.15px',
    textAlign: 'center',
    color: '#575757',
    marginBottom: '2rem',
  },
  textfield: {
    '& label': {
      fontFamily: 'Luckiest Guy',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      letterSpacing: '0.15px',
      color: 'rgba(180, 179, 179, 0.5)',
    },
    '& label.MuiInputLabel-outlined': {
      width: 'calc(100% - 28px)'
    },
    '& label.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      width: 'unset',
    },
    '& label.MuiFormLabel-root.Mui-focused': {
      color: '#FFA258'
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'unset'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.66)'
    },
    width: '100%',
    height: '40px',
    margin: 'none',
    marginBottom: '1rem',
  },
  input: {
    '&.MuiInputBase-root': {
      color: '#575757',
    },
    '&.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused': {
      borderColor: 'none',
    },
    boxSizing: 'border-box',
    borderRadius: '24px',
  },
  option: {
    justifyContent: 'center',
  },
  button: {
    color: '#FFFFFF',
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.45px',
    textTransform: 'uppercase',
    width: '100%',
    boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: '24px',
  },
  error: {
    color: 'red',
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '26px'
  }
}))

interface HostRoomCardProps {
  socket: any;
}

const HostRoomCard: FC<HostRoomCardProps> = ({ socket }) => {
  const classes = useStyles();
  const [adminNickname, setAdminNickname] = useState<string>('');
  const [adminCode, setAdminCode] = useState<string>('');
  const [maxPlayersSelected, setMaxPlayersSelected] = useState<string>('');
  const [topicSelected, setTopicSelected] = useState<Topic>({ topic_id: -1, title: '' } as Topic);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [errorCodeMsg, setErrorCodeMsg] = useState<string>('');
  const [creatingGame, setCreatingGame] = useState<boolean>(false);
  const { organization_id } = useParams<{ organization_id: string }>();
  const maxPlayers = [2, 3, 4, 5, 6];
  const intl = useIntl();

  const history = useHistory();

  const buttonClickAudio = useAudio(SOUNDS.BUTTON_CLICK, {})

  useEffect(() => {
    getTopics();
  }, [])

  const getTopics = () => {
    axios.get(`${config.apiUrl}/topics/by/organizationId/${organization_id}`).then(res => {
      setTopics(res.data);
    }).catch(console.log);
  }

  const onCreateGame = () => {
    buttonClickAudio.play();
    if (adminNickname && adminCode && maxPlayersSelected && topicSelected.topic_id !== -1) {
      setCreatingGame(true);
      setErrorMsg('');
      setErrorCodeMsg('');
      let adminId = localStorage.getItem('userId');
      axios.patch(`${config.apiUrl}/rooms/new`, {
        adminId: adminId ? parseInt(adminId) : null,
        adminNickname,
        adminCode,
        maxPlayers: maxPlayersSelected,
        topicId: topicSelected.topic_id
      }).then(res => {
        localStorage.setItem('userId', res.data.user.id);
        history.push(`/game/${res.data.room.roomCode}`)
      }).catch((error: any) => {
        setCreatingGame(false);
        if (error.response) {
          if (error.response.data.statusCode === 500) setErrorMsg('Interal server error');
          else if (error.response.data.statusCode === 400) {
            switch (error.response.data.message[0]) {
              case 'name can only contain letters, spaces and numbers': setErrorMsg(intl.formatMessage({ id: "homeView.HostRoomCard.nameError" })); break;
              default: setErrorMsg('Bad Request Error');
            }
          }
          else {
            switch (error.response.data.message) {
              case 'No room available': setErrorCodeMsg(intl.formatMessage({ id: "homeView.HostRoomCard.roomError" })); break;
              case `Code doesn't exists or has expired`: setErrorCodeMsg(intl.formatMessage({ id: "homeView.HostRoomCard.codeError" })); break;
            }
          }
        }
      })
    } else {
      setErrorMsg(intl.formatMessage({ id: "homeView.HostRoomCard.formError" }));
    }
  }

  const handleSelectTopic = (event: any) => {
    const t = topics.find(t => t.topic_id === event.target.value);
    if (t) setTopicSelected(t)
  }

  const orangeTheme = createTheme({
    palette: {
      primary: {
        main: '#FFA258',
      }
    }
  });
  const greenTheme = createTheme({
    palette: {
      primary: {
        main: '#83CD24',
      }
    }
  });

  return (
    <Card
      className={classes.root}
    >
      <Box >
        <Typography
          variant='h6'
          className={classes.title}
        >
          <FormattedMessage id="homeView.HostRoomCard.createRoom" />
        </Typography>
      </Box>
      <form >
        <ThemeProvider theme={orangeTheme}>
          <TextField
            className={classes.textfield}
            inputProps={{ style: { textAlign: 'center' } }}
            InputProps={{ className: classes.input }}
            label={intl.formatMessage({ id: "homeView.HostRoomCard.nameInput" })}
            variant="outlined"
            id="nickname"
            size="small"
            onChange={e => setAdminNickname(e.target.value)}
            error={adminNickname === '' && errorMsg !== ''}
          />
          <TextField
            className={classes.textfield}
            inputProps={{ style: { textAlign: 'center' } }}
            InputProps={{ className: classes.input }}
            label={errorCodeMsg ? errorCodeMsg : intl.formatMessage({ id: "homeView.HostRoomCard.codeInput" })}
            variant="outlined"
            id="adminCode"
            size="small"
            onChange={e => setAdminCode(e.target.value)}
            error={(adminCode === '' && errorMsg !== '') || errorCodeMsg !== ''}
          />
          <TextField
            className={classes.textfield}
            inputProps={{ style: { textAlign: 'center' } }}
            InputProps={{ className: classes.input }}
            id="maxPlayers"
            size="small"
            select
            label={intl.formatMessage({ id: "homeView.HostRoomCard.playersInput" })}
            variant="outlined"
            value={maxPlayersSelected}
            onChange={e => setMaxPlayersSelected(e.target.value)}
            error={maxPlayersSelected === '' && errorMsg !== ''}
          >
            {
              maxPlayers.map(option => (
                <MenuItem key={option} value={option} className={classes.option}>
                  {option}
                </MenuItem>
              ))
            }
          </TextField>
          <TextField
            className={classes.textfield}
            inputProps={{ style: { textAlign: 'center' } }}
            InputProps={{ className: classes.input }}
            id="topics"
            size="small"
            select
            label={intl.formatMessage({ id: "homeView.HostRoomCard.themeInput" })}
            variant="outlined"
            value={topicSelected.topic_id === -1 ? '' : topicSelected.topic_id}
            onChange={handleSelectTopic}
            error={topicSelected.topic_id === -1 && errorMsg !== ''}
          >
            {topics &&
              topics.map(option => (
                <MenuItem key={option.topic_id} value={option.topic_id} className={classes.option}>
                  {option.title}
                </MenuItem>
              ))
            }
          </TextField>
        </ThemeProvider>
        <ThemeProvider theme={greenTheme}>
          {
            adminNickname && adminCode && maxPlayersSelected && topicSelected && errorMsg &&
            <Box className={classes.error}>
              {errorMsg}
            </Box>
          }
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={onCreateGame}
            disabled={creatingGame}
          >
            {!creatingGame ? intl.formatMessage({ id: "homeView.HostRoomCard.create" }) : intl.formatMessage({ id: "homeView.HostRoomCard.creating" })}
          </Button>
        </ThemeProvider>
      </form>
    </Card>
  )
}

export default HostRoomCard
